<template>
    <!-- Router Link -->
    <button 
        :type="submit && 'submit'"
        v-html="label"
        :style="cssProps"
        :class="[color && color, rounded && 'rounded', stretch && 'stretch']"
        @click="$emit('click')"
    />
</template>

<script>
export default {
    props: {
        color: String,
        label: String,
        rounded: Boolean,
        stretch: Boolean,
        submit: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        cssProps() {
            return {
                '--display': this.stretch ? 'block' : 'inline-block',
            }
        },
    },
    emits: ['click']
}
</script>

<style lang="scss" scoped>

button {
    display: var(--display);

    font-family: var(--standard-font);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;

    padding-block-start: 1.25rem;
    padding-block-end: 1.25rem;
    padding-inline-start: 3rem;
    padding-inline-end: 3rem;

    border: none;

    cursor: pointer;
}

.accent {
    background-color: var(--accent);
    color: var(--whit);
}

.accent:hover {
    filter: brightness(115%);
}

.primary {
    background-color: var(--primary);
    color: var(--whit);
}

.primary:hover {
    filter: brightness(115%);
}

.secondary {
    background-color: var(--secondary);
    color: var(--dark_grey);
}

.secondary:hover {
    filter: brightness(103%);
}

.white {
    background-color: var(--whit);
    color: var(--dark_grey);
}

.white:hover {
    filter: brightness(85%);
}

.rounded {
    border-radius: 5px;
}

.stretch {
    width: 100%;
}

</style>