const resource = {
    en: {
        heading: 'Error 401',
        message: 'You do not have sufficient permissions to view the resource.',
        back_to_home: 'Back to home',
    },
    de: {
        heading: 'Fehler 401',
        message: 'Sie haben nicht die benötigten Rechte um die Ressource anzusehen.',
        back_to_home: 'Zurück zur Homepage',
    }
}

export default resource;